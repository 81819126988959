<template>
  <v-container class="mt-0 pt-0 mx-0 px-0 mb-4 pb-4">
    <v-layout row wrap class="mt-0 pt-0 mb-4" justify-space-around align-center="">
      <v-flex mb-3 md4 mt-0 pt-0 order-md2>
        <v-layout column align-center>
          <v-flex>
            <v-card>
              <v-card-text class="text-xs-center text-sm-left grey--text">
                <div>Being a Leatherman is about wearing your gear with pride. Add exclusive MLM gear to your wardrobe!</div>
                <div class="mt-3 mb-3">
                  As you may know we have updated our logo very recently. We are currently preparing new design of our items.
                  Please check back soon, so you can get yours.
                </div>              


                <!-- HIDDEN AS SHOP IS CURRENTLY NOT ACTIVE -->
                <div class="mt-3 mb-3" v-show="false">
                  We are pleased to partner with
                  <a
                    href="https://houseofhun.co.uk/collections/manchester-leathermen"
                    target="_blank"
                    rel="noreferrer"
                  >House of Hun</a> (Password:
                  <strong>MLM2018</strong>) for a new range of t-shirts, including two designs by internationally-acclaimed fetish photographer Matt Spike.
                </div>              
                </v-card-text>
                <v-divider v-show="false" />
                

                <!-- HIDDEN AS SHOP IS CURRENTLY NOT ACTIVE -->
                <v-card-actions v-show="false">                                
                  <v-btn href="https://houseofhun.co.uk/collections/manchester-leathermen" target="_blank" rel="noreferrer" class="primary grey--text text--darken-4 mx-3 mt-3" block>
                  <v-icon left>mdi-shopping</v-icon>
                  Get yours now</v-btn>                
                </v-card-actions>      
                
                        
                <v-card-text ma-0 pa-0 class="text-xs-center grey--text" v-show="false">                            
                  Password:
                  <strong>MLM2018</strong>
                
                </v-card-text>
                
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md5 pb-4 order-md1>
        <v-carousel height="360" touch prev-icon="mdi-dark mdi-arrow-left" next-icon="mdi-dark mdi-arrow-right">
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"            
          ></v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
            titleTemplate: '%s | Our shop',
            link: [{ rel: "canonical", href: "https://www.manchesterleathermen.com/shop" }]
    },
  data() {
    return {
      items: [
        {
          src: "/assets/whitelogolargegildan.png"
        },
        {
          src: "/assets/capfront.png"
        },
        {
          src: "/assets/blacklogoCClarge.png"
        },
        {
          src: "/assets/black-logo-CC-small.png"
        },
      ]
    };
  }
};
</script>
<style>

</style>
